import Oidc from 'oidc-client'

function getAuthorityUrl() {
  const hostnames = window.location.hostname.split('.');
  if (hostnames.length == 4) {
    const subdomain = hostnames[0]
    return `https://${subdomain}.auth.myresman.com/auth`
  } else {
    return process.env.VUE_APP_AUTHORITY_URL
  }
}

const origin = window.location.origin

const authority = getAuthorityUrl()

const mgr = new Oidc.UserManager({
  userStore: new Oidc.WebStorageStateStore(),
  authority: authority,
  client_id: 'commercialVueJs',
  redirect_uri: origin + '/commercial-app/static/callback.html',
  response_type: 'code',
  scope: 'openid profile commercialApi resmanCoreUrl',
  post_logout_redirect_uri: origin + '/commercial-app/index.html',
  silent_redirect_uri: origin + '/commercial-app/static/silent-renew.html',
  accessTokenExpiringNotificationTime: 60,
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: true
})

Oidc.Log.logger = console
Oidc.Log.level = Oidc.Log.INFO

mgr.events.addUserLoaded(function () {
  console.log('New User Loaded：', arguments)
})

mgr.events.addAccessTokenExpiring(function () {
  console.log('AccessToken Expiring：', arguments)
  const event = new CustomEvent('tokenExpiring')
  parent.dispatchEvent(event)
})

mgr.events.addAccessTokenExpired(function () {
  console.log('AccessToken Expired：', arguments)
  const event = new CustomEvent('tokenExpired')
  parent.dispatchEvent(event)
})

mgr.events.addSilentRenewError(function () {
  console.error('Silent Renew Error：', arguments)
})

mgr.events.addUserSignedOut(function () {
  console.log('UserSignedOut：', arguments)
  mgr.getUser()
    .then(user => {
      let url = user.profile.resman_core_url
      if (url && url !== '') {
        window.location = url + '/Access/SignOut'
      }
    })
  localStorage.clear()
})

export default class AuthService {
  getAccessTokenExpiringNotificationTime () {
    return mgr.settings._accessTokenExpiringNotificationTime
  }

  // Renew the token manually
  renewToken () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.signinSilent().then(function (user) {
        if (user == null) {
          self.signIn(null)
        } else {
          return resolve(user)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      })
    })
  }

  // Get the user who is logged in
  getUser () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      })
    })
  }

  // Check if there is any user logged in
  getSignedIn () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(false)
        } else {
          return resolve(true)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      })
    })
  }

  // Redirect of the current window to the authorization endpoint.
  signIn () {
    mgr.signinRedirect().catch(function (err) {
      console.log(err)
    })
  }

  // Redirect of the current window to the end session endpoint
  signOut () {
    mgr.getUser()
      .then(user => {
        let url = user.profile.resman_core_url
        if (url && url !== '') {
          window.location = url + '/Access/SignOut'
        }
      })
    localStorage.clear()
  }
}
